.CatItem {
    
    display: flex;
    flex-direction: column;
    height: 260px;
    justify-content: space-between;
    width: 100%;
    user-select: none;

    &__img {
        object-fit: cover;
        border-radius: 15px;
        width: 100%;
        max-height: 150px;
    }
    &__name {
        margin-top: 15px;
        margin-bottom: 0;
    }
    
    &__main {
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        background-color: #fff;
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &_name {
            padding: 30px 20px;
            color: #666666;
            font-weight: 500;
            text-align: center;
            
        }
    }
}