.banners__pic-item img {
  width: 100%;
}
.banners__pic-item {
  position: relative;
  border: 2px dashed #aaa;
  width: 100%;
  min-height: 200px;
  border-radius: 21px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 8px;
  overflow: hidden;
  
  &:hover {
    .baners__pic-item--backdrop {
      transition: 0.3s all;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}
.baners__pic-item--new {
  width: 150px;
  height: 140px;
}

.baners__pic-item--backdrop {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.baners__pic-item--new p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 140px;
}
.banners__pageBody {
  flex-direction: column;
}